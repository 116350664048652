@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: #4dbac0;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --primary-clor-blue: #4dbac0;
    --primary-clor-gray: #f5f6f6;
    --primary-clor-gray2: #f2f2f2;
    --primary-clor-gray3: #9ba1a8;
    --primary-clor-blue: #4267b2;
    --primary-clor-red: #ff3141;
    --primary-color-red2: #ffe6e8;
    --primary-bg-light: #cce8e9;
    --theme-gray-1: rgba(255, 255, 255, 0.56);
    --theme-gray-2: rgba(255, 255, 255, 0.8);
    --space-1: 4px;
    --space-2: 14px;
    --space-3: 24px;
    --space-4: 34px;
    --space-5: 4px;
    --space-6: 4px;
    --space-7: 4px;
    --theme-color-blue2: rgba(77, 186, 192, 0.24);
    --theme-color-blue3: rgba(77, 186, 192, 0.12);

    --primary-color: #4dbac0;
    --white: #ffffff;
    --black: #000000;
    --fill-0: #ffffff;
    --fill-1: #000000;
    --fill-2: #80878e;
    --fill-3: #9ba1a8;
    --fill-4: #ebecee;
    --fill-5: #f6f7f7;
    --fill-6: rgba(0, 0, 0, 0.5);
    --fill-7: #ffe6e8;
    --fill-8: #e3effb;
    --fill-9: #e3effb;

    --text-title: #4dbac0;
    --text-white: #ffffff;
    --text-black: #000000;
    --text-primary: #000000;
    --text-secondary: #80878e;
    --text-tertiary: #9ba1a8;
    --text-link: #1890ff;
    --text-warning: #d89614;
    --text-error: #ff3141;
    --text-success: #68d634;

    --bg-primary: #f5f6f6;
    --bg-secondary: #ffffff;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: #4dbac0;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --primary-color: #4dbac0;
    --white: #ffffff;
    --black: #000000;
    --fill-0: #ffffff;
    --fill-1: #000000;
    --fill-2: #a3a3a3;
    --fill-3: #7a7a7a;
    --fill-4: #393939;
    --fill-5: #212121;
    --fill-6: rgba(255, 255, 255, 0.5);
    --fill-7: #1f0608;
    --fill-8: #030f1a;
    --fill-9: #0c1a06;

    --text-title: #2a6d6d;
    --text-white: #ffffff;
    --text-black: #000000;
    --text-primary: #ffffff;
    --text-secondary: #a3a3a3;
    --text-tertiary: #4a4d51;
    --text-link: #4a4d51;
    --text-warning: #d89614;
    --text-error: #ff3141;
    --text-success: #4e8a32;

    --bg-primary: #000000;
    --bg-secondary: #141414;
  }

  .theme-color-blue1 {
    color: #4dbac0;
  }

  .theme-color-blue2 {
    color: rgba(77, 186, 192, 0.24);
  }

  .theme-color-red1 {
    color: rgba(255, 49, 65, 1);
  }

  .theme-color-red2 {
    color: rgba(255, 230, 232, 1);
  }

  .theme-bg-blue1 {
    background-color: #4dbac0;
  }

  .theme-bg-blue2 {
    background-color: rgba(77, 186, 192, 0.24);
  }

  .theme-bg-red1 {
    background-color: rgba(255, 49, 65, 1);
  }

  .theme-bg-red2 {
    background-color: rgba(255, 230, 232, 1);
  }

  .logreg-inputs .bg-grey {
    background-color: #f5f6f6;
  }

  .logreg-inputs .border-grey {
    border: 2px solid #4dbac03d;
  }

  .logreg-inputs .border-red {
    border: 1px solid #dc2626;
  }

  .logreg-inputs .red {
    color: #dc2626;
  }

  .logreg-inputs .scale-102 {
    transform: scale(1.02);
  }

  .rotate-135 {
    rotate: 135deg;
  }

  /* 全局样式 */
  * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  body {
    margin: 0;
    padding: 0;
  }

  /* 对于需要可选择的特定元素(如输入框)，可以单独启用选择 */
  input,
  textarea {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  /* 当内容未超过最大高度时，隐藏滚动条 */
  .hide-extra-scrollbar:not(:hover)::-webkit-scrollbar {
    display: none !important;
  }

  .hide-extra-scrollbar:not(:hover) {
    -ms-overflow-style: none !important;
    /* IE 和 Edge */
    scrollbar-width: none !important;
    /* Firefox */
  }

  /* 为所有的滚动条设置样式 */
  .diy-scrollbar::-webkit-scrollbar {
    width: 5px;
    /* 设置滚动条的宽度 */
  }
  /* 为所有的滚动条设置样式 */
  .diy-scrollbar-0::-webkit-scrollbar {
    width: 0px;
    /* 设置滚动条的宽度 */
  }

  /* 滚动条轨道 */
  .diy-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* 设置轨道的背景颜色 */
    margin: 30px 0;
  }

  /* 滚动条的滑块 */
  .diy-scrollbar::-webkit-scrollbar-thumb {
    background: #9ba1a8;
    /* 设置滑块的背景颜色 */
    border-radius: 2.5px;
    /* 添加上下的圆角效果 */
    border-top-left-radius: 2.5px;
    border-top-right-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
  }

  /* 当鼠标悬停在滑块上 */
  .diy-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* 设置滑块在悬停状态下的背景颜色 */
  }

  /* input输入框光标 */
  input:placeholder-shown {
    caret-color: #1890ff !important;
  }
  Textarea:placeholder-shown {
    caret-color: #1890ff !important;
  }

  input {
    caret-color: #1890ff !important;
  }

  @media screen and (max-width: 768px) {
    div[role='dialog'] {
      background-color: rgba(255, 255, 255, 0.79);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  div[data-radix-scroll-area-viewport] > div {
    display: block !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: inherit !important;
  }

  input:autofill {
    background-color: transparent !important;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply box-border bg-background text-foreground;
  }
}

@layer utilities {
  .min-h-screen {
    min-height: 100vh;
    min-height: 100dvh;
  }

  .h-screen {
    height: 100vh;
    height: 100dvh;
  }
}

/* 隐藏输入框上下箭头 */
#searchPageNumberInputMinPrice::-webkit-inner-spin-button,
#searchPageNumberInputMinPrice::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 兼容Firefox */
#searchPageNumberInputMinPrice[type='number'] {
  -moz-appearance: textfield;
}

/* 隐藏输入框上下箭头 */
#searchPageNumberInputMaxPrice::-webkit-inner-spin-button,
#searchPageNumberInputMaxPrice::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 兼容Firefox */
#searchPageNumberInputMaxPrice[type='number'] {
  -moz-appearance: textfield;
}

/* 隐藏输入框上下箭头 */
#addShoppingCartNumberInput::-webkit-inner-spin-button,
#addShoppingCartNumberInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 兼容Firefox */
#addShoppingCartNumberInput[type='number'] {
  -moz-appearance: textfield;
}

/* 修改swiper的分页器颜色 */
.swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: rgba(77, 186, 192, 0.24) !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background-color: #4dbac0 !important;
}
.swiper-pagination-fraction {
  width: auto !important;
  min-width: 51px !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  color: white !important;
  padding: 6px 0px !important;
  background-color: #9ba1a8 !important;
  border-radius: 36px !important;
  right: 16px !important;
  bottom: 16px !important;
  left: auto !important;
}

/* 商品详情里的content宽度设置为100% */
#productDetailContent {
  > div {
    width: 100% !important;
  }

  > table {
    width: 100% !important;

    > tbody {
      > tr {
        > td {
          width: 25% !important;
        }
      }
    }
  }
}

/* 后面需改写到滑动验证组件中去 */
.verifybox {
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #e4e7eb;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* left: 50%;
  top:50%;
  transform: translate(-50%,-50%); */
}

.verifybox-top {
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
  text-align: left;
  font-size: 16px;
  color: #45494c;
  border-bottom: 1px solid #e4e7eb;
  box-sizing: border-box;
}

.verifybox-bottom {
  padding: 15px;
  box-sizing: border-box;
}

.verifybox-close {
  position: absolute;
  top: 13px;
  right: 9px;
  width: 24px;
  height: 24px;
  text-align: center;
  cursor: pointer;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  /* display: none; */
  transition: all 0.5s;
}

.verify-tips {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #fff;
}

.suc-bg {
  background-color: rgba(92, 184, 92, 0.5);
  filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#7f5CB85C, endcolorstr=#7f5CB85C);
}

.err-bg {
  background-color: rgba(217, 83, 79, 0.5);
  filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#7fD9534F, endcolorstr=#7fD9534F);
}

.tips-enter,
.tips-leave-to {
  bottom: -30px;
}

.tips-enter-active,
.tips-leave-active {
  transition: bottom 0.5s;
}

/* ---------------------------- */
/*常规验证码*/
.verify-code {
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
  border: 1px solid #ddd;
}

.cerify-code-panel {
  height: 100%;
  overflow: hidden;
}

.verify-code-area {
  float: left;
}

.verify-input-area {
  float: left;
  width: 60%;
  padding-right: 10px;
}

.verify-change-area {
  line-height: 30px;
  float: left;
}

.varify-input-code {
  display: inline-block;
  width: 100%;
  height: 25px;
}

.verify-change-code {
  color: #337ab7;
  cursor: pointer;
}

.verify-btn {
  width: 200px;
  height: 30px;
  background-color: #337ab7;
  color: #ffffff;
  border: none;
  margin-top: 10px;
}

/*滑动验证码*/
.verify-bar-area {
  position: relative;
  background: #ffffff;
  text-align: center;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.verify-bar-area .verify-move-block {
  position: absolute;
  top: 0px;
  left: 0;
  background: #fff;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  box-shadow: 0 0 2px #888888;
  -webkit-border-radius: 1px;
  border-radius: 1px;
}

.verify-bar-area .verify-move-block:hover {
  background-color: #337ab7;
  color: #ffffff;
}

.verify-bar-area .verify-left-bar {
  position: absolute;
  top: -1px;
  left: -1px;
  background: #f0fff0;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid #ddd;
}

.verify-img-panel {
  margin: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 3px;
  position: relative;
}

.verify-img-panel .verify-refresh {
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.verify-img-panel .icon-refresh {
  font-size: 20px;
  color: #fff;
}

.verify-img-panel .verify-gap {
  background-color: #fff;
  position: relative;
  z-index: 2;
  border: 1px solid #fff;
}

.verify-bar-area .verify-move-block .verify-sub-block {
  position: absolute;
  text-align: center;
  z-index: 3;
  /* border: 1px solid #fff; */
}

.verify-bar-area .verify-move-block .verify-icon {
  font-size: 18px;
}

.verify-bar-area .verify-msg {
  z-index: 3;
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.verify-img-out {
  height: 200px;
}

.btn {
  border: none;
  outline: none;
  width: 300px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  background-color: #409eff;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1em;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
}

.siderEmbed {
  margin-top: 80px;
}

.sliderPopup,
.pointPopup,
.pointEmbed {
  margin-top: 40px;
}

.blog-detail-content audio,
.blog-detail-content canvas,
.blog-detail-content embed,
.blog-detail-content iframe,
.blog-detail-content img,
.blog-detail-content object,
.blog-detail-content svg,
.blog-detail-content video {
  display: inline;
}

.blog-detail-content li::before {
  content: '•'; /* 使用圆点作为项目符号 */
  font-size: 1.2em; /* 调整大小 */
  margin-right: 10px;
  transform: translateY(-50%); /* 精确地垂直居中 */
}

.blog-detail-content blockquote,
.blog-detail-content dl,
.blog-detail-content dd,
.blog-detail-content h1,
.blog-detail-content h2,
.blog-detail-content h3,
.blog-detail-content h4,
.blog-detail-content h5,
.blog-detail-content h6,
.blog-detail-content hr,
.blog-detail-content figure,
.blog-detail-content p,
.blog-detail-content pre {
  margin: revert;
}

#secondCategoryScrollArea::-webkit-scrollbar {
  width: 10px; /* 设置滚动条宽度 */
}
#secondCategoryScrollArea::-webkit-scrollbar-track {
  border-radius: 100px; /* 设置滚动条滑块圆角 */
  background-color: #ebecee; /* 设置滚动条背景色 */
}
#secondCategoryScrollArea::-webkit-scrollbar-thumb {
  background-color: #9ba1a8; /* 设置滚动条滑块颜色 */
  border-radius: 100px; /* 设置滚动条滑块圆角 */
}
.noScrollbar {
  scrollbar-width: none; /* Firefox */
}
.noScrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

.productReviewImageWrapper .productReviewImageMask {
  display: none !important;
}
.productReviewImageWrapper .ant-image-preview-mask,
.ant-image-preview-wrap {
  z-index: 99999 !important;
}

.ant-image-preview-operations-wrapper {
  z-index: 99999;
}
.ant-image-preview-operations-wrapper .ant-image-preview-close,
.ant-image-preview-operations-wrapper .ant-image-preview-footer {
  pointer-events: all;
}

.ant-image-preview-operations-wrapper .ant-image-preview-close {
  padding: 0;
  background: transparent;
}
